import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Common/Wrapper";
import { Col, Row, Tooltip } from "react-bootstrap";
import Product from "../../components/Product";
import Axios from "axios";
import { useSelector } from "react-redux";
import ModalWapper from "../../components/Common/ModalWapper";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const MyPromotion = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const params = useParams();
  const vendorId = params?.vendorId;

  const [data, setData] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const { userInfo } = userLogin;
  const [show, setShow] = useState(false);
  const [sharedUrl, setSharedUrl] = useState("");
  const [id, setId] = useState("");

  const getPromotedProduct = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    const response = await Axios.get(
      `/api/promoters/promoted-products?id=${
        vendorId ? vendorId : userInfo?._id
      }`,
      config
    );
    setData(response?.data);
  };

  useEffect(() => {
    getPromotedProduct();
  }, [vendorId, userInfo?._id]);

  const handleCopyTextFromArea = () => {
    const area = document.querySelector("#clipboard-area");
    area.select();
    document.execCommand("copy");
    setLinkCopied(true);
  };

  // http://localhost:3005/merch
  useEffect(() => {
    if (show === true) {
      setSharedUrl(
        `${process.env.REACT_APP_Home_URL}/merch/product/${id}?referralId=${
          vendorId ?? userInfo?._id
        }`
      );
    } else {
      setId("");
    }
  }, [show]);
  const promotedProduct = Array.isArray(data?.data)
    ? data.data.filter((s) => s._id === id)
    : [];

  const promoterCommission =
    promotedProduct && promotedProduct[0]?.promoterCommission;

  return (
    <div>
      <Wrapper>
        <Col
          md={12}
          lg={false ? 12 : 9}
          xl={false ? 12 : 9}
          // lg={userInfo?.isAdminLogin === true ? 12 : 9}
          // xl={userInfo?.isAdminLogin === true ? 12 : 9}
        >
          <div className="paymentMethod-main" style={{ margin: "40px" }}>
            <Row className="align-items-center">
              <Col>
                <h4 className="heading">My Promotions</h4>
              </Col>
            </Row>

            <div className="products products-grid mgs-products">
              <div className="mgs-productsWraper">
                <Row>
                  {data?.data?.map((product) => {
                    return (
                      <Col
                        key={product._id}
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                      >
                        <Product
                          vendorId={vendorId}
                          userInfo={userInfo}
                          product={product}
                          vendor={true}
                          isPromotion={true}
                          setId={() => setId(product?._id)}
                          openModal={() => setShow(true)}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Wrapper>
      {show && (
        <ModalWapper
          show={show}
          setShow={setShow}
          title="Share product url"
          isPromotion={true}
        >
          <p>Share this product with your friends</p>
          <p
            style={{
              color: "#2aa8f2",
              fontSize: "14px",
            }}
          >
            You will get
            {promoterCommission && ` ${promoterCommission} %`} commission for
            sharing this product after matching the requirements of privacy
            policy
          </p>
          <div className="copy-wraper">
            <input
              className="event-link-copy"
              id="clipboard-area"
              width={"100%"}
              value={sharedUrl || ""}
            />
            <div
              className="clipboard-icon"
              style={{ background: `${linkCopied ? "green" : "#2aa8f2"}` }}
              onClick={() => handleCopyTextFromArea()}
            >
              <i
                className={`fa fa-light ${
                  linkCopied ? "fa-check" : "fa-paste"
                }`}
              ></i>
            </div>
          </div>
        </ModalWapper>
      )}
    </div>
  );
};

export default MyPromotion;
