import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <>
      <div className="progress-bar-sec">
        <ul>
          <li>
            {step1 ? (
              <LinkContainer to="/login" className="active">
                <Nav.Link>
                  <span className="icon">1</span>
                  <span className="name">Sign In</span>
                </Nav.Link>
              </LinkContainer>
            ) : (
              <Nav.Link disabled>
                <span className="icon">1</span>
                <span className="name">Sign In</span>
              </Nav.Link>
            )}
          </li>
          <li>
            {step2 ? (
              <LinkContainer to="/shipping" className="active">
                <Nav.Link>
                  <span className="icon">2</span>
                  <span className="name">Shipping</span>
                </Nav.Link>
              </LinkContainer>
            ) : (
              <Nav.Link disabled>
                <span className="icon">2</span>
                <span className="name">Shipping</span>
              </Nav.Link>
            )}
          </li>
          <li>
            {step3 ? (
              <LinkContainer to="/delivery" className="active">
                <Nav.Link>
                  <span className="icon">3</span>
                  <span className="name">Delivery</span>
                </Nav.Link>
              </LinkContainer>
            ) : (
              <Nav.Link disabled>
                <span className="icon">3</span>
                <span className="name">Delivery</span>
              </Nav.Link>
            )}
          </li>
          <li>
            {step4 ? (
              <LinkContainer to="/payment" className="active">
                <Nav.Link>
                  <span className="icon">4</span>
                  <span className="name">Payment</span>
                </Nav.Link>
              </LinkContainer>
            ) : (
              <Nav.Link disabled>
                <span className="icon">4</span>
                <span className="name">Payment</span>
              </Nav.Link>
            )}
          </li>
        </ul>
      </div>
      {/* <Nav className='justify-content-center mb-4'>
      <Nav.Item>
        {step1 ? (
          <LinkContainer to='/login'>
            <Nav.Link>Sign In</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Sign In</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to='/shipping'>
            <Nav.Link>Shipping</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Shipping</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to='/payment'>
            <Nav.Link>Payment</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Payment</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to='/placeorder'>
            <Nav.Link>Place Order</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>Place Order</Nav.Link>
        )}
      </Nav.Item>
    </Nav> */}
    </>
  );
};

export default CheckoutSteps;
