import React from "react";
import Paginate from "../../components/Paginate";
import { Button, Table } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Pending = ({ promoters, fetchPromoters }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleResendInvitation = async (email) => {
    const response = await Axios.post("/api/promoters/resend-invitation", {
      email,
    });
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    return response;
  };
  const handleDeletePromoter = async ({ promoterId }) => {
    const response = await Axios.post("/api/promoters/delete", {
      promoterId,
      ownerId: userInfo?._id,
    });
    if (response.status === 200) {
      toast.success(response?.data?.message);
      await fetchPromoters();
    }
    return response;
  };
  return (
    <div>
      <Table striped bordered hover responsive className="table-sm">
        <thead>
          <tr className="text-center">
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {promoters?.map((item, index) => {
            return (
              <tr className="text-center" key={index}>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>
                  <Button
                    className="btn btn-primary"
                    onClick={() => handleResendInvitation(item?.email)}
                  >
                    Resend Invitation
                  </Button>
                  <Button
                    style={{
                      background: "none",
                      marginLeft: "10px",
                      color: "red",
                    }}
                    onClick={() => {
                      handleDeletePromoter({ promoterId: item?._id });
                    }}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Paginate
        url="/admin/productlist"
        //  page={pageNumber} pages={pages}
      />
    </div>
  );
};

export default Pending;
