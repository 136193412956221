import React, { useEffect, useState } from "react";

import ModalWapper from "../components/Common/ModalWapper";

import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Axios from "axios";
import Product from "../components/Product";
import { Empty } from "antd";
import { TV_URL, SOCIAL_URL, MUSIC_URL } from "../urls/OtherUrls";
import { useDispatch, useSelector } from "react-redux";
import VendorSidebar from "../screens/VendorSidebar";

const VendorPromotion = ({ match, location, history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo: UI } = userLogin;

  const keyword = match.params.keyword ? match.params.keyword : "";
  const vendorId = match.params.vendorId;

  console.log(vendorId, match?.params, "-vendor id");
  const categoryId = match.params.categoryId;
  const name = match.params.name;
  const pageNumber = match.params.pageNumber || 1;

  //category

  const [loading, setLoading] = useState(() => true);
  const [noti, setNoti] = useState(() => "");
  const [page, setPage] = useState(() => 0);
  const [pages, setPages] = useState(() => 0);

  const [profile, setProfile] = useState(() => false);

  const [wishlist, setWishlist] = useState(() => []);

  const [userInfo, setUserInfo] = useState(() => {});
  const [categories, setCategories] = useState(() => []);
  const [active, setActive] = useState(() => "");
  const [followings, setFollowings] = useState(() => {
    return { follower: 0, following: 0 };
  });
  console.log(vendorId, "- params ");

  const [data, setData] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);
  const [show, setShow] = useState(false);
  const [sharedUrl, setSharedUrl] = useState("");
  const [id, setId] = useState("");

  const getPromotedProduct = async () => {
    let config = {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    const response = await Axios.get(
      `/api/promoters/promoted-products?id=${
        vendorId ? vendorId : userInfo?._id
      }`,
      config
    );
    setData(response?.data);
  };

  useEffect(() => {
    getPromotedProduct();
  }, [vendorId, userInfo?._id]);

  const handleCopyTextFromArea = () => {
    const area = document.querySelector("#clipboard-area");
    area.select();
    document.execCommand("copy");
    setLinkCopied(true);
  };

  // http://localhost:3005/merch
  useEffect(() => {
    if (show === true) {
      setSharedUrl(
        `${process.env.REACT_APP_Home_URL}/merch/product/${id}?referralId=${
          vendorId ?? userInfo?._id
        }`
      );
    } else {
      setId("");
    }
  }, [show]);

  useEffect(() => {
    console.log(categoryId);
    setLoading(true);
    // if
    if (vendorId) {
      if (categoryId) {
        Axios.get(
          `/api/products/vendor-get-products-category/${vendorId}/${categoryId}`
        )
          .then((res) => {
            console.log(res.data);
            setUserInfo(res.data.vendor);
            setCategories(res.data.products);
            if (UI) {
              if (UI._id == res.data.vendor._id) {
                setProfile(true);
              }
            }
            if (res.data.data.length != 0) {
              setActive(res.data.data[0].categoryId);
            }
            setLoading(false);
            console.log(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        Axios.get(`/api/products/vendor-get-products-category/${vendorId}`)
          .then((res) => {
            console.log(res.data);
            setUserInfo(res.data.vendor);
            setCategories(res.data.products);
            if (UI) {
              if (UI._id == res.data.vendor._id) {
                setProfile(true);
              }
            }
            if (res.data.data.length != 0) {
              setActive(res.data.data[0].categoryId);
            }
            setLoading(false);
            console.log(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  }, [vendorId, categoryId]);

  useEffect(() => {
    if (UI) {
      let config = {
        headers: {
          Authorization: `Bearer ${UI.token}`,
        },
      };
      Axios.get(`/api/users/get-followers`, config)
        .then((res) => {
          console.log(res.data);
          setFollowings(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [UI]);

  return (
    <section className="accountMain-wraper">
      <Container>
        <Row>
          <Col md={12}>{/* <h1 className="main-heading"> </h1> */}</Col>
        </Row>
        <Row>
          <>
            <VendorSidebar match={match} profile={profile} />
          </>
          <Col
            md={12}
            lg={false ? 12 : 9}
            xl={false ? 12 : 9}
            // lg={userInfo?.isAdminLogin === true ? 12 : 9}
            // xl={userInfo?.isAdminLogin === true ? 12 : 9}
          >
            <div className="paymentMethod-main" style={{ margin: "40px" }}>
              <Row className="align-items-center">
                <Col>
                  <h4 className="heading">My Promotions</h4>
                </Col>
              </Row>

              <div className="products products-grid mgs-products">
                <div className="mgs-productsWraper">
                  <Row>
                    {data?.data?.map((product) => {
                      return (
                        <Col
                          key={product._id}
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12"
                        >
                          <Product
                            vendorId={vendorId}
                            userInfo={userInfo}
                            product={product}
                            vendor={true}
                            isPromotion={true}
                            setId={() => setId(product?._id)}
                            openModal={() => setShow(true)}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {show && (
        <ModalWapper
          show={show}
          setShow={setShow}
          title="Share product url"
          isPromotion={true}
        >
          <p>Share this product with your friends</p>
          <p
            style={{
              color: "#2aa8f2",
              fontSize: "14px",
            }}
          >
            You will get
            {/* {data?.promoterCommission && `${data?.promoterCommission} %`}{" "} */}
            commission for sharing this product after matching the requirements
            of privacy policy
          </p>
          <div className="copy-wraper">
            <input
              className="event-link-copy"
              id="clipboard-area"
              width={"100%"}
              value={sharedUrl || ""}
            />
            <div
              className="clipboard-icon"
              style={{ background: `${linkCopied ? "green" : "#2aa8f2"}` }}
              onClick={() => handleCopyTextFromArea()}
            >
              <i
                className={`fa fa-light ${
                  linkCopied ? "fa-check" : "fa-paste"
                }`}
              ></i>
            </div>
          </div>
        </ModalWapper>
      )}
    </section>
  );
};
// const ProfileScreen=[]
export default VendorPromotion;
