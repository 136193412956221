import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import Paginate from "../../components/Paginate";
import Axios from "axios";
import { toast } from "react-toastify";

const Assigned = ({ tab, promoters, productId, fetchPromoters }) => {
  const [promoterId, setPromoterId] = useState([]);
  const [data, setData] = useState();

  const handleAssignPromoter = async () => {
    try {
      const response = await Axios.post(
        tab === "Assigned"
          ? "/api/promoters/unassign"
          : "/api/promoters/assign",
        {
          promoterIds: promoterId,
          productId,
        }
      );
      setData(response);
      fetchPromoters();
      toast.success(response.data);
    } catch (e) {
      console.log(e, "------error");
    }
  };
  return (
    <div>
      {promoters.length > 0 ? (
        <Table
          striped
          bordered
          hover
          responsive
          className="table-sm position-relative"
        >
          <>
            <thead>
              <tr className="text-center">
                <th>SELECT</th>
                <th>NAME</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {promoters?.map((item, index) => {
                return (
                  <tr className="text-center" key={index}>
                    <td>
                      {" "}
                      <input
                        type="checkbox"
                        onChange={() => {
                          if (promoterId.includes(item?._id)) {
                            setPromoterId(
                              promoterId.filter((id) => id !== item?._id)
                            );
                          } else {
                            setPromoterId([...promoterId, item?._id]);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <h6>{item?.name + item?.lastName}</h6>
                    </td>
                    <td>
                      <h6>{item?.email}</h6>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </>
        </Table>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <h4
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            No Promoter {tab}
          </h4>
        </div>
      )}
      <Paginate
        url="/admin/productlist"
        //  page={pageNumber} pages={pages}
      />

      <Button
        type="submit"
        className="btn"
        disabled={!promoterId}
        style={{ position: "relative", right: "0px" }}
        onClick={() => handleAssignPromoter()}
      >
        {tab === "Assigned" ? "Unassign" : "Assign"}
      </Button>
    </div>
  );
};

export default Assigned;
