import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

const ModalWapper = ({
  show,
  setShow,
  title,
  closeButtonText,
  isPromotion,
  submitButtonText,
  handleModalSubmit,
  isLoading,
  ...props
}) => {
  const handleclose = () => {
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleclose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{props.children}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleclose}>
          {closeButtonText ? closeButtonText : "Close"}
        </Button>
        {!isPromotion && (
          <Button
            variant="primary"
            onClick={handleModalSubmit}
            type="submit"
            disabled={isLoading}
          >
            {submitButtonText ? submitButtonText : "Submit"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWapper;
