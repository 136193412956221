import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import VendorSidebar from "../../screens/VendorSidebar";

const Wrapper = ({ children }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <section className="accountMain-wraper">
      <Container
        fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
      >
        <div className="row">
          <div className="col-md-12">
            <h1 className="main-heading">My Account</h1>
          </div>
        </div>
        <div className="d-flex">
          {userInfo && userInfo?.isAdminLogin !== true && (
            <VendorSidebar profile={true} />
          )}
          {children}
        </div>
      </Container>
    </section>
  );
};

export default Wrapper;
