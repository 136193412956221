import React, { useState, useEffect } from "react";
import SideBar from "../components/Sidebar";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Doughnut, Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Axios from "axios";
import { DatePicker, Space, Layout, Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Loader from "../components/Loader";
import Message from "../components/Message";

import VendorSidebar from "./VendorSidebar";
import CardWrapper from "../components/CardWrapper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Paginate from "../components/Paginate";

const { Header, Footer, Content } = Layout;
const MyEarnings = ({ history }) => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const { _id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!userInfo) {
    history.push("/login");
  }

  const [loading, setLoading] = useState(true);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");
  const [dateInterval, setDateInterval] = useState("");
  const [result, setResult] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [model, setModel] = useState(false);
  const [page, setPage] = useState(1);

  const promoterEarningProductPage = !!_id;

  const promoterEarningPage = location.pathname === "/promoter-earnings";
  let config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
  const getEarningDetails = async (page) => {
    let apiRoute;
    if (promoterEarningPage) {
      apiRoute = `/api/promoters/promoter-earning?id=${userInfo?._id}&${
        page && page
      }&`;
    } else if (promoterEarningProductPage) {
      apiRoute = `/api/promoters/promoter-earning-by-product?id=${userInfo?._id}&productId=${_id}&`;
    } else {
      apiRoute = "/api/miscellaneous/estimate-earnings?";
    }
    try {
      let qry = "";
      dateInterval
        ? (qry = `${apiRoute}timeline=${dateInterval}`)
        : startDate && endDate
        ? (qry = `${apiRoute}startDate=${startDate}&endDate=${endDate}`)
        : (qry = `${apiRoute}`);
      let response = await Axios.get(qry, config);
      if (response.status === 200) {
        setResult(response.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEarningDetails();
    window.scrollTo(0, 0);
  }, [dateInterval]);

  const getWalletDetails = async () => {
    try {
      const response = await Axios.get(
        `/api/promoters/wallet/${userInfo?._id}`,
        config
      );

      if (response.status === 200) {
        const walletData = response.data.wallet;
        setWallet(walletData);
      }
    } catch (e) {
      console.error("Error fetching wallet details:", e);
    }
  };

  useEffect(() => {
    getWalletDetails();
  }, []);

  const handleDateInterval = (calendar) => {
    setStartDate("");
    setEndDate("");
    setDateInterval(calendar);
  };

  const handlePay = async () => {
    if (withdrawAmount > wallet[0].amount) {
      toast.error("Insufficient balance");
      return;
    }

    const data = {
      _id: userInfo?._id,
      withdraw: withdrawAmount,
    };
    try {
      const response = await Axios.post(
        `/api/promoters/wallet/withdraw`,
        data,
        config
      );
      console.log(response, "response");
      if (response.status === 200) {
        toast.success("Withdrawal successfully done");
      }
      setModel(false);
    } catch (err) {
      console.log(err.response?.data?.error);
      toast.error(err.response?.data?.error ?? "something_went_wrong");
    }
  };

  const salesCategory = {
    labels: [
      "Clothing",
      "Music Instruments",
      "Health & Beauty Products",
      "Health Supplements",
      "Electronics",
      "Travel Products",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const monthlySales = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Total income",
        data: [12, 19, 3, 5, 2, 3, 16, 10, 18, 15, 8, 6],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <section>
        <Container
          fluid={userInfo && userInfo?.isAdminLogin === true ? true : false}
        >
          <Row>
            <div className="col-md-12">
              <h1 className="main-heading"> </h1>
            </div>
          </Row>

          <Row>
            {userInfo && userInfo?.isAdminLogin !== true && (
              <VendorSidebar profile={true} />
            )}
            <Col
              md={12}
              lg={userInfo?.isAdminLogin === true ? 12 : 9}
              xl={userInfo?.isAdminLogin === true ? 12 : 9}
            >
              <div className="paymentMethod-main wishlist-item-sec">
                <Row>
                  <Col md={9}>
                    <h4 className="heading">
                      {promoterEarningPage
                        ? "My Promotion Earning"
                        : promoterEarningProductPage
                        ? "My Promotional Product Earning"
                        : "My Earnings"}
                    </h4>
                  </Col>
                  {promoterEarningPage && (
                    <Col md={3}>
                      <Button
                        style={{
                          backgroundColor: "#2aa8f2",
                          color: "white",
                          marginTop: "20px",
                        }}
                        onClick={() => setModel(true)}
                        variant="btn"
                      >
                        Wallet -{" $ "}
                        {wallet ? parseFloat(wallet[0]?.amount).toFixed(2) : 0}
                      </Button>
                    </Col>
                  )}
                </Row>
                {error && <Message variant="danger">{error}</Message>}

                {
                  <Layout className="myEarningsPicker">
                    <Header style={{ backgroundColor: "white" }}>
                      <Row>
                        {!promoterEarningPage && (
                          <Col md={6}>
                            <span>
                              <h3>
                                Commission Rate:{" "}
                                {result?.PlatformCommissionRate}%{" "}
                              </h3>
                            </span>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col xl={7}>
                          {!promoterEarningProductPage && (
                            <>
                              <RangePicker
                                onChange={(e) => {
                                  if (e.length != 0) {
                                    setStartDate(
                                      e[0]?.toISOString()?.split("T")[0]
                                    );
                                    setEndDate(
                                      e[1]?.toISOString()?.split("T")[0]
                                    );
                                  }
                                }}
                              />
                              <Button
                                type="primary"
                                icon={<SearchOutlined />}
                                onClick={() => {
                                  setDateInterval("");
                                  getEarningDetails();
                                }}
                              >
                                Estimate
                              </Button>
                            </>
                          )}
                        </Col>

                        <Col xl={5}>
                          <div className="monthlyButtons">
                            <Button
                              className="primary "
                              onClick={() => handleDateInterval("today")}
                            >
                              Today
                            </Button>
                            <Button
                              className="secondary"
                              onClick={() => handleDateInterval("week")}
                            >
                              Weekly
                            </Button>
                            <Button
                              className="success"
                              onClick={() => handleDateInterval("month")}
                            >
                              Monthly
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Header>
                  </Layout>
                }

                {loading ? (
                  <Loader />
                ) : (
                  <section className="earnings-boxes">
                    <Row>
                      {promoterEarningPage && (
                        <Col xl={4} sm={6}>
                          <CardWrapper
                            title={"My Promotional Products"}
                            data={result?.totalProducts}
                            icon={"fas fa-shopping-bag"}
                            colorData={"dash-icon text-primary"}
                          />
                        </Col>
                      )}
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={" Total Orders"}
                          data={result?.totalOrders}
                          icon={"fas fa-shopping-bag"}
                          colorData={"dash-icon text-orange"}
                        />
                      </Col>

                      {!promoterEarningPage && !promoterEarningProductPage && (
                        <Col xl={4} sm={6}>
                          <CardWrapper
                            title={" Total Sales"}
                            data={"$" + result?.totalSales}
                            icon={"fas fa-money-bill"}
                            colorData={"dash-icon text-warning"}
                          />
                        </Col>
                      )}
                      <Col xl={4} sm={6}>
                        <CardWrapper
                          title={"My Earnings"}
                          data={
                            "$" +
                            (promoterEarningPage || promoterEarningProductPage
                              ? result?.totalPromoterEarning
                              : result?.totalEarnings)
                          }
                          icon={"fas fa-dollar-sign"}
                          colorData={"dash-icon text-primary"}
                        />
                      </Col>

                      {!promoterEarningPage && !promoterEarningProductPage && (
                        <>
                          <Col xl={4} sm={6}>
                            <CardWrapper
                              title={" Total Platform Fees"}
                              data={"$" + result?.totalPlatformFees}
                              icon={"fas fa-dollar-sign"}
                              colorData={"dash-icon text-warning"}
                            />
                          </Col>
                          <Col xl={4} sm={6}>
                            <CardWrapper
                              title={" Total Merchant Fees"}
                              data={"$" + result?.totalMerchantFees}
                              icon={"fas fa-dollar-sign"}
                              colorData={"dash-icon text-secondary"}
                            />
                          </Col>
                          <Col xl={4} sm={6}>
                            <CardWrapper
                              title={"Shipping Charges"}
                              data={"$" + result?.totalShippingCharges}
                              icon={"fas fa-dollar-sign"}
                              colorData={"dash-icon text-primary"}
                            />
                          </Col>
                          <Col xl={4} sm={6}>
                            <CardWrapper
                              title={" Total Promoter Commission"}
                              data={"$" + result?.totalPromoterCommission}
                              icon={"fas fa-money-bill"}
                              colorData={"dash-icon text-warning"}
                            />
                          </Col>
                          <Col xl={4} sm={6}>
                            <CardWrapper
                              title={"Total Escrow Amount"}
                              data={
                                "$" +
                                (!!result?.totalEscrowAmount
                                  ? result?.totalEscrowAmount
                                  : 0)
                              }
                              icon={"fas fa-dollar-sign"}
                              colorData={"dash-icon text-primary"}
                            />
                          </Col>
                          <Col xl={4} sm={6}>
                            <CardWrapper
                              title={"Total Refunded Amount"}
                              data={
                                "$" +
                                (!!result?.totalRefundedAmount
                                  ? result?.totalRefundedAmount
                                  : 0)
                              }
                              icon={"fas fa-dollar-sign"}
                              colorData={"dash-icon text-primary"}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </section>
                )}
                {(promoterEarningPage || promoterEarningProductPage) && (
                  <section className="earnings-boxes widget-table-two">
                    <Row>
                      <Col xl={12}>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr className="text-center">
                                {promoterEarningPage ? (
                                  <>
                                    <th>
                                      <div className="th-content">Image</div>
                                    </th>
                                    <th>
                                      <div className="th-content">
                                        Product Name
                                      </div>
                                    </th>
                                    <th>
                                      <div className="th-content">Orders</div>
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    <th>
                                      <div className="th-content ">Name</div>
                                    </th>
                                    <th>
                                      <div className="th-content ">
                                        Product price
                                      </div>
                                    </th>
                                  </>
                                )}

                                <th>
                                  <div className="th-content ">
                                    Promotion Earning
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="td-content">
                              {result?.orderData?.length > 0 ? (
                                result?.orderData?.map((item, index) => (
                                  <tr
                                    key={index}
                                    className="text-center"
                                    onClick={() => {
                                      promoterEarningPage &&
                                        history.push(
                                          `/promoter-earning/${item?.productId}`
                                        );
                                    }}
                                  >
                                    {promoterEarningPage ? (
                                      <>
                                        <td>
                                          <img
                                            className=""
                                            src={item?.productImage}
                                            alt="avatar"
                                          />
                                        </td>
                                        <td>{item?.productName}</td>
                                        <td>{item?.totalOrders}</td>
                                      </>
                                    ) : (
                                      <>
                                        <td>{item?.BillingAddress?.name}</td>
                                        <td>$ {item?.price}</td>
                                      </>
                                    )}

                                    <td>
                                      ${" "}
                                      {promoterEarningPage
                                        ? item?.promoterEarning
                                        : item?.promoterCommission || 0}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr className="d-flex text-center justify-content-center">
                                  No record found
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <Paginate
                            url={`?page=${result?.page}`}
                            page={result?.page}
                            pages={result?.pages}
                          />
                        </div>
                      </Col>
                    </Row>
                  </section>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {model && (
          <Modal
            show={model}
            onHide={() => {
              setModel(false);
            }}
          >
            <Modal.Header>
              <Row>
                <Col md={10}>
                  <div>Wallet</div>
                </Col>
                <Col md={2}>
                  <div>
                    <Button variant="btn">
                      {" "}
                      Available balance -{" $"}
                      {wallet[0]
                        ? parseFloat(wallet[0]?.amount).toFixed(2)
                        : "Loading..."}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <label htmlFor="withdrawAmount" className="form-label">
                  Withdraw Amount
                </label>
                <input
                  id="withdrawAmount"
                  type="number"
                  className="form-control"
                  autoFocus
                  onChange={(e) => setWithdrawAmount(e.target.value)}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => setModel(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={() => handlePay()}>
                Withdraw
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </section>
    </>
  );
};

export default MyEarnings;
