import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Common/Wrapper";
import { Button, Col, Nav, Row, Table } from "react-bootstrap";
import Paginate from "../../components/Paginate";
import Assigned from "./Assigned";
import Pending from "./Pending";
import ModalWapper from "../../components/Common/ModalWapper";
import Search from "antd/lib/input/Search";
import Axios from "axios";
import { message } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const AssignPromoter = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const params = useParams();
  const [activeTab, setActiveTab] = useState("Assigned");
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [promoters, setPromoters] = useState([]);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    _id: "",
  });

  const [type, setType] = useState(activeTab);

  let isMounted = true;
  const fetchPromoters = async () => {
    try {
      const response = await Axios.get(
        `/api/promoters?promoterType=${type}&productId=${params?.id}&search=${value}&_id=${userInfo._id}`
      );
      if (isMounted) {
        setPromoters(response?.data?.data || []);
      }
    } catch (e) {
      console.error(e);
      if (isMounted) setPromoters([]);
    }
  };

  const handleAddPromoter = async () => {
    try {
      const response = await Axios.post("/api/promoters", {
        ...data,
        _id: userInfo?._id,
      });

      if (response.data.status === true) {
        setData();
        setOpen(false);
        toast.success("Promoter created successfully");
        await fetchPromoters();
      } else {
        toast.error(response.data.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error adding promoter:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
        toast.error(error.response.data.message || "Server error occurred");
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("No response from the server. Please try again.");
      } else {
        console.error("Request error:", error.message);
        toast.error("An unexpected error occurred");
      }
    }
  };

  useEffect(() => {
    setType(activeTab);
  }, [activeTab]);

  useEffect(() => {
    fetchPromoters();
    return () => {
      isMounted = false;
    };
  }, [type, value, params?.id]);
  return (
    <Wrapper>
      <Col
        md={12}
        lg={false ? 12 : 9}
        xl={false ? 12 : 9}
        // lg={userInfo?.isAdminLogin === true ? 12 : 9}
        // xl={userInfo?.isAdminLogin === true ? 12 : 9}
      >
        <div className="paymentMethod-main">
          <div className="heading-wraper">
            <h4 className="heading">{activeTab} Promoters</h4>
            <Button
              variant="secondary"
              className="btn"
              onClick={() => setOpen(true)}
            >
              <i className="fas fa-plus"></i> Add Promoter
            </Button>
          </div>
          <div
            className="myorder-info"
            style={{ display: "flex", marginBottom: "16px" }}
          >
            <nav className="order-tabs">
              {["Assigned", "Unassigned", "Pending"].map((tab, index) => (
                <Nav.Link
                  key={index}
                  onClick={() => {
                    setActiveTab(tab);
                  }}
                  className={activeTab === tab && "active"}
                >
                  <span>{tab}</span>
                </Nav.Link>

                // <button
                //   key={tab}
                //   onClick={() => {
                //     setActiveTab(tab);
                //   }}
                //   style={{
                //     padding: "8px 16px",
                //     marginRight: "8px",
                //     cursor: "pointer",
                //     background: activeTab === tab ? "#1890ff" : "#f0f0f0",
                //     color: activeTab === tab ? "#fff" : "#000",
                //     border: "1px solid #ccc",
                //     borderRadius: "4px",
                //   }}
                // >
                //   {tab}
                // </button>
              ))}
            </nav>
          </div>
          <div className="table-promoter-wraper">
            <div className="search-box">
              <Search
                onChange={(e) => setValue(e.currentTarget.value)}
                placeholder="Search Promoter by name or email"
              />
            </div>
            {activeTab === "Assigned" || activeTab === "Unassigned" ? (
              <Assigned
                tab={activeTab}
                promoters={promoters}
                productId={params?.id}
                fetchPromoters={fetchPromoters}
              />
            ) : (
              <Pending promoters={promoters} fetchPromoters={fetchPromoters} />
            )}
          </div>
        </div>
      </Col>
      {!!open && (
        <ModalWapper
          show={!!open}
          closeButtonText={"Close"}
          setShow={() => setOpen()}
          title={"Add Promoter"}
          handleModalSubmit={() => handleAddPromoter()}
        >
          <label>Name*</label>
          <input
            placeholder="Name"
            className="form-control"
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <label>Email*</label>
          <input
            placeholder="Email"
            className="form-control"
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <label>Phone*</label>
          <input
            placeholder="Phone"
            className="form-control"
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
        </ModalWapper>
      )}
    </Wrapper>
  );
};

export default AssignPromoter;
