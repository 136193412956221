import axios from "axios";

const getCartItems = async (token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    let response = await axios.get(`/api/cart/cart-items`, config);
    return response.data;
  } catch (err) {
    console.log(err, "Error in getting cart items -----");
  }
};

export { getCartItems };
